import moment from 'moment';
import { convertOneHourPercentageToMinutesAndSeconds } from 'src/utils/helpers';

function storePerformancePlotData({ graphData, graphPerformance, categories: newCategories, labels, indexesToRemove }: any) {
  const filteredGraphTypes =
    graphData?.length > 0
      ? graphData.filter(
        (graph: any) => graph.name === 'store_average_time_graph' || graph.name === 'store_total_footfall_graph',
      )
      : [];
  const mappedData = [] as any[];
  filteredGraphTypes.forEach((d: any, i: number) => {
    const parsedData = {
      ...d,
      data: d?.data?.filter((_: any, index: number) => !indexesToRemove.includes(index)),
    };
    if (parsedData.data && parsedData.data.length > 0) {
      const label = labels.find((label: any) => label.id === parsedData.name);
      const axis = label?.id === 'store_total_footfall_graph' ? 'xaxis' : 'yaxis';

      const footfallText =
        graphPerformance.xaxis.visible && label?.id === 'store_total_footfall_graph'
          ? '<b style="color: #333;">Footfall:</b> <span style="color: #53D2C5;">%{y} </span>'
          : '';
      const avgtimeText =
        graphPerformance.yaxis.visible && label?.id !== 'store_total_footfall_graph'
          ? '<b style="color: #333;">Avg. Time Spent:</b> <span style="color: #53D2C5;">%{customdata}</span>'
          : '';

      mappedData.push({
        color: label?.color,
        customdata: parsedData.data.map((time: number) => {
          if (parsedData.name === 'store_average_time_graph') {
            return convertOneHourPercentageToMinutesAndSeconds(time);
          }
          return time;
        }),
        id: parsedData.name,
        mode: 'lines',
        name: label?.name,
        type: 'scatter',
        visible: true,
        x: newCategories,
        y: parsedData.data.map((val: number) => {
          if (parsedData.name === 'store_average_time_graph') {
            return val * 60;
          }
          return val.toFixed();
        }),
        yaxis: `y${i + 1}`,
        line: {
          color: label?.colorHex,
          shape: 'spline',
          smoothing: 0,
          width: graphPerformance && graphPerformance[axis].visible ? 2 : 0,
        },
        hoverinfo: 'y',
        hoverlabel: {
          bgcolor: '#fff',
          font: { size: 14, family: 'Lato' },
          bordercolor: '#ebeaea',
        },
        hovertemplate: `${footfallText}${avgtimeText}<extra></extra>`,
      });
    }
  });

  return mappedData;
}

function storePerformanceCSVDownloadData({ graph_data, categories }: any) {
  const performanceData = [] as any;
  performanceData.push(['Date/Time', 'Total Footfall', 'Avg. Time']);
  if (graph_data) {
    if (!categories) {
      return performanceData;
    }
    categories.forEach((cat: number) => {
      performanceData.push([moment(cat * 1000).format('DD/MM/YY HH:mm')]);
    });
    performanceData.forEach((cat: any, i: number) => {
      if (i === 0) {
        return;
      }
      graph_data.forEach((item: any) => {
        if (item.name === 'store_total_footfall_graph') {
          if (performanceData[i + 1]) {
            item.data.forEach((data: any, i: number) => {
              performanceData[i + 1][1] = data;
            });
          }
        }
        if (item.name === 'store_average_time_graph') {
          item.data.forEach((data: any, i: number) => {
            if (performanceData[i + 1]) {
              performanceData[i + 1][2] = convertOneHourPercentageToMinutesAndSeconds(data);
            }
          });
        }
      });
    });
  }
  return performanceData;
}

function storePerformancePlotLayout({ graphPerformance, mappedData, categories: newCategories, tickFormat }: any) {
  if (mappedData.length === 0) {
    return {
      xaxis: { visible: false },
      yaxis: { visible: false },
      annotations: [
        {
          text: 'No matching data found',
          xref: 'paper',
          yref: 'paper',
          showarrow: false,
          font: { size: 14, color: '#3F404F' },
        },
      ],
    };
  }

  const showXaxis = graphPerformance.xaxis.visible;
  const showYaxis = graphPerformance.yaxis.visible;
  const newPlotLayout = {
    title: '',
    showlegend: false,
    responsive: true,
    displayModeBar: false,
    margin: { t: 0 },
    hovermode: graphPerformance && (showXaxis || showYaxis) ? 'x unified' : 'closest',
    annotations: [
      {
        text: graphPerformance && !showXaxis && !showYaxis ? 'Please select the graph you want to display' : '',
        xref: 'paper',
        yref: 'paper',
        showarrow: false,
        font: { size: 14, color: '#3F404F' },
      },
    ],
    xaxis: {
      tickmode: newCategories.length ? 'auto' : undefined,
      hoverformat: tickFormat,
      tickformat: tickFormat,
      tickvals: [newCategories],
      ticktext: newCategories.map((c: any) => moment(c).format('DD MMM HH:mm')),
      domain: [0.01, 0.95],
      showticklabels: true,
      showgrid: true,
    },
    yaxis: {
      tickfont: { color: '#53D2C5' },
      position: 0,
      type: 'linear',
      visible: (graphPerformance && graphPerformance.xaxis.visible) || false,
    },
    yaxis2: {
      visible: (graphPerformance && graphPerformance.yaxis.visible) || false,
      tickfont: { color: '#5361ff' },
      overlaying: 'y',
      side: 'right',
      position: 0.97,
      showgrid: false,
      type: 'linear',
    },
  };
  return newPlotLayout;
}

export { storePerformancePlotData, storePerformanceCSVDownloadData, storePerformancePlotLayout };
